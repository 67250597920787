import React from "react"

import { withAccountLogin } from "./withAccountLogin"
import { AccountLoginForm } from "./Form/AccountLoginForm"
import { AccountRegisterForm } from "./Form/AccountRegisterForm"
import { Column, Row, StyledContainer } from "./AccountLoginStyles"

export const AccountLogin = withAccountLogin(() => (
  <StyledContainer>
    <Row>
      <Column border>
        <AccountLoginForm />
      </Column>

      <Column>
        <AccountRegisterForm />
      </Column>
    </Row>
  </StyledContainer>
))
